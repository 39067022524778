.container {
  padding-left: 0;
  padding-right: 0;
}

h1 {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 50px;
  margin-bottom: 4rem;
  
}

h2 {
  font-stretch: extra-expanded;
}

.top-heading {
  font-size: 48px;
  font-weight: bold;
}

.top:after {
  display: inline-block;
  width: 100%;
  font-weight: 600;
}

.sub-top-container{
  padding-top: 1rem;
  padding-bottom: 4rem;
}



@media (max-width: 600px) {
  .no-phone {
    display: none;
  }
}

@media screen and (min-width: 600px) {
  .button-narrow {
    // display: flex;
    // justify-content: center;
    width: 60% !important;
    
    
    
  }
}