.grade-container{
    display: inline-flex;
    border: 1px solid #333333;
    border-radius: 8px;
    margin-right: 10px;
    display: grid;
    grid-template-columns: 40px ;
    grid-template-rows: 20px ;
    width: 80px;
    height: 22px;
    /* background-color: #FA910B; */
    padding-right: 1px;
    
}

.right-box{
    
    
    grid-area: 1 / 2 / 1 / 3;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    
    border-radius: 8px;
    display: inline-flex;
    justify-content: center;
    
    
}
p.left-box{
    margin-top: -5px;
    margin-bottom: 5px;
    
}

.left-box{
    display: inline-flex;
    background-color: #C4C4C4;
    justify-self: flex-start;
    width: 40px;
    height: 22px;
    border: 1px solid #333333;
    border-radius: 8px;
    grid-area: 1 / 1 / 2 / 1;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    
    justify-content: center;
    margin-bottom: 1px;
    margin-left: -1px;
    margin-top: -1px;
    padding-bottom: 5px;

}
p.right-box{
    justify-self: center;
}

