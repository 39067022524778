.accountContainer {
  width: 70%;
  margin-left: 12%;
  margin-top: 5%;
  margin-bottom: 10%;
  .myInfoContainer {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    table {
      td {
        font-weight: normal;
        border-bottom: 1px solid #E9E9E9;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 18px;
        line-height: 23px;
      }
    }
    .account-link{
      text-decoration: none;
      font-size: 14px;
      line-height: 18px;
      color: #007AFF;
    }
    #edit-link{
      position: absolute;
      color: #4F4F4F;
      font-weight: bold;
      text-align: right;
      text-transform: uppercase;
      
    }
    div{
      width: 200px;
      margin-right: -10vw;
    }
  }
}

.deleteAccBtn {
  background-color: #FDC825;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 900;
  padding-top: 3%;
  padding-bottom: 3%;
  border: none;
}

.ViewTermsOfUseContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 10%;
  margin-bottom: 5%;
  .title {
    margin-top: 5%;
  }
  .agreementContainer {
    .subtitle {
      font-weight: bold;
    }
    .p {
      margin-top: 3%;
    }
  }
}

@media only screen and (max-width: 600px) {
.accountContainer{
  margin-left: 0;
}
}

@media only screen and (min-width: 1800px) {
  .accountContainer{
    .myInfoContainer{
    table{
      div{
        margin-right: -6.8vw;
      }
    }
  }
  }
  }