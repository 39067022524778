.validation-warning{
    width: 10px;
    
}

.validation-text{
    padding-left: 12px;
    color: #D22E2E;
    font-size: 12px;
    line-height: 15px;
}