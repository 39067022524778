.pointer-div{
    display: inline-flex;
    z-index: 2;
    position: absolute;
    top: 195px;

    
}

.pointer-div-show{
    display: inline-flex;
    z-index: 2;
    position: absolute;
    top: 250px;

    
}

.pointer-arrow{
    position: absolute;
    top: 0;
    left: -7px;
}

.pointer-line{
    position: absolute;
    top: 10px;
    left: -40px;
    
}

#verified{
    /* left: 15rem; */
    margin-left: 7vw;
}