.navContainer {
  // display: flex;
  flex-direction: row;
  // justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: #e9e9e9;
  .navRow {
    display: flex;
    flex-direction: row;
    margin-right: 3vw;
    .logContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 8px 0;
      .logoImg {
        margin-right: 20px;
      }
    }
  }
  // .navLog{
  //   align-items: right;
  // }
  .navItem {
    // width: 8vw;
    margin: 0 1.5vw;
    display: flex;
    // justify-content: center;
    align-items: center;
    .navItemText {
      // font-size: 1rem;
      font-weight: 400;
      text-decoration: none;
    }
  }
  #logo {
    margin-left: 4vw;
  }
  #logout {
    border: none;
    background-color: #e9e9e9;
    padding-left: 0;
    color: #979797;
  }
  #nav-toggle {
    margin-right: 4vw;
  }
  // #myaccount {
  //   margin-left: 10px;
  // }
}

.navbar {
  justify-content: space-between;
  // padding-top: 0px;
  // padding-bottom: 0px;
}

/*** -----------MEDIA QUERIES ----------- ***/

@media only screen and (max-width: 900px) {
  .navContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    .navSecond {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 5%;
      margin-bottom: 5%;
      .navItem {
        width: 100%;
      }
    }
    .navLog {
      margin-top: 5%;
      margin-bottom: 5%;
    }
    .navbar {
      padding: 0px;
    }
  }
  // .navContainer {
  //   height: auto;
  //   display: flex;
  //   flex-direction: column;
  //   .navRow {
  //     display: flex;
  //     justify-content: center;
  //   }
  //   .navSecond {
  //     display: flex;
  //     flex-direction: column;
  //     width: 80%;
  //     .navItem {
  //       width: 100%;
  //       text-align: center;
  //       padding-top: 1%;
  //       padding-bottom: 1%;
  //       bor
  //     }
  //   }
  // }
  // .navContainer #myaccount {
  //   margin-left: 0
  // }
  //
  // #loginSolo {
  //   margin-right: 30px;
  // }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .navContainer {
    height: auto;
    .navRow {
      display: flex;
      flex-direction: column;
      .logContainer {
        .logoImg {
          img {
            height: 50px;
          }
          margin-right: 8px;
        }
        .logoText {
          img {
            height: 17px;
          }
        }
      }
    }
    // .navSecond {
    //   border: 1px solid red;
    //   display: flex;
    //   flex-direction: column;
    //   width: 80%;
    //   .navItem {
    //     width: 90%;
    //     margin-left: 10%;
    //   }
    // }
  }
  // #loginSolo {
  //   margin-right: 30px;
  // }
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 2) {
  .navItemText {
    font-size: 1.5rem;
  }  
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {

}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {

}

/* -----------  ----------- */
@media screen 
  and (min-device-width: 1200px) {
  .navbar {
    padding: 0px;
  }
}