.admin-table{
    
    height: 48px;
}

td{
    font-family: 'Mulish', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
}