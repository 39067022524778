$el-green: #139929;

.custom {
  background-color: $el-green;
  padding: 0.455rem 0.75rem;
  font-weight: 900 !important;
  font-size: 18px;
  line-height: 23px;
  color: #FFFFFF;
}

.btn-contact {
  width: 200px;
}

