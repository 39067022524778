
  .subnav {
    
    /* justify-content: space-evenly; */
    font-family: Mulish;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 20px;
    
    
  }
  
  .sub-nav-list {
    display: inline-flex;
    
    justify-content: flex-start;

    
    
  }

  .sub-nav-list-item {
    display: inline-flex;
    
    justify-self: center;
    
    margin: 0 4vw 0 0;
   
    
    align-items: flex-end;
    justify-content: center;
    
  }
  
  .sub-nav-link {
    
    display: inline-flex;
    
    text-decoration: none;
    color: #979797;
  }

  .sub-nav-active{
    font-weight: bold;
    color: #333;
  }
  
  .subNav-hr{
    border: 2px solid #E9E9E9;
    
  }

