.img-project-card{
    width: 100%;
    border: 1px solid #333333;
    border-radius: 15px;
    margin-bottom: 25px;
}

.project-card{
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-top: 8px;
    padding-bottom: 20px;
   
    margin-top: 20px;
    
    
}

h4.project-blurb{
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */


    /* Electricasa Black */

    color: #333333;

}

.no-data{
   
    filter: grayscale(100%);
}

/* On screens that are 700px or less, set the background color to blue */
@media screen and (max-width: 700px) {
    .project-card {
      margin-bottom: 20px;
      
      width: 80vw;
      margin-left: -6vw;
     
    }
  }