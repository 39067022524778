.about-title{
  margin-bottom: 4rem;
}

.h3_about{
  font-weight: 700;
  font-size: 48px;
  line-height: 50px;
  padding-left: 10px;
}

.item_container {
          
  .logo_box {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;
    margin-top: 60px;
  }
    
  .p_about{
    padding-left: 10px;
    padding-right: 10px;
  }
}

.footer_about {
  width: 100%;
  position: fixed;
  bottom: 0;
}

@media only screen and (max-device-height: 710px) {
  .footer_about {
    position: relative;
  }
}