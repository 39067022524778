div.project-header{
    display: inline-flex;
    
    width: 100%;
    justify-content: space-between;
}

.component-title{
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;

    color: #FA910B;
}

div.project-year{
    
    width: 68px;
    height: 24px;
    left: 473px;
    top: 570px;

    /* Electricasa Red */
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    
    
}

p.project-year{
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    
    /* identical to box height */


    /* Electicasa Grey 1 */

    color: #E9E9E9;
}

h4.project-blurb{
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */


    /* Electricasa Black */

    color: #333333;

}
p.project-blurb{
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */


    /* Electricasa Grey 2 */

    color: #979797;

}

div.estimate{
    background: #EF9829;
    border: 2px solid #EF9829;
    box-sizing: border-box;
    border-radius: 5px;
    display: inline-flex;
    width: 100%;
    justify-self: center;
}

p.estimate{
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    margin: 5px 5px 5px 5px;

    color: #FFFFFF;
}

.green{
    background: #139929;
    /* Electricasa Red */

    border: 2px solid #139929;
}

.orange{
    background: #FDC825;
    /* Electricasa Red */

    border: 2px solid #FDC825;
}
    
.red{
    background: #D22E2E;
    /* Electricasa Red */

    border: 2px solid #D22E2E;

}