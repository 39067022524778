.admin-table {
background: #FFFFFF;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
}

.admin-table-header{
    background-color: #E9E9E9;
}
th{
color:  #FA910B;
font-family: Mulish;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 18px;
height: 48px;
}