#title {
    margin-left: 20%;
  }
  
  #subtitle {
    margin-left: 20%;
    margin-top: 5%;
    margin-bottom: 3%;
  
  }

  // STEVEN NOTE: The code that is commented out below is redundant to MyCasaDashboard.scss EXCEPT for the justify-content styling, which is set to "space-between"
  .myCasaContainer{
    // width: 80%;
    // margin-left: 10%;
    // margin-top: 5%;
    // margin-bottom: 10%;
    // .myCasaTitle {
    //   margin-left: 3%;
    // }
    .showContainer {
      // display: flex;
      // flex-direction: column;
      .showRow {
        // display: flex;
        // flex-direction: row;
        justify-content: center;
        // align-items: center;
        .items {
          // width: 300px;
          // height: auto;
          // display: flex;
          // flex-direction: row;
          justify-content: center;
          // margin: 30px;
          // padding: 12px;
          // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          // border-radius: 10px;
        }
      }
    }
  }

  /* On screens that are 700px or less, set the background color to blue */
@media screen and (max-width: 700px) {
    .myCasaTitle {
        
        margin-bottom: -10vw;
      }
  }