.homeContainer {
  &__contentContainer {
    &--list {
      &--item {
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &-img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}
