// Toggle CSS
.toggle-container{
  display: flex;
  justify-content: center;
  margin-top: 40px;

}
.data-toggle{
  width: 130px;
  height: 40px;
  border-radius: 10px 0 0 10px;
  display: flex;
  justify-content: center;  
}

.analysis-toggle{
  width: 130px;
  height: 40px;
  border-radius: 0 10px 10px 0;
  display: flex;
  justify-content: center;
}

.myCasaSubtitle {
  margin-bottom: 20px;
}

#active-toggle{
  background: #FA910B;
  color: #FFFFFF;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
}

#inactive-toggle{
  background: #E9E9E9;
  color: #979797;
  
}

.toggle-text{
  align-self: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
}

// END TOGGLE CSS

.myCasaTitle{
  
  border: 1px solid white;
  
}

#title {
  margin-left: 20%;
}

#subtitle {
  margin-left: 20%;
  margin-top: 5%;
  margin-bottom: 3%;

}
.create_container {
  width: 70%;
  margin-left: 15%;
  .create_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 5%;
  }
  .create_items {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 28%;
    height: 200px;
     .link {
      width: 80%;
      height: 160px;
    }
    .tag {
      margin-top: 2%;
    }
  }
}

.img {
  width: 200px;
  height: 160px;
}
.noposting {
  width: 200px;
  height: 160px;
  background-color: #E9E9E9;
  border-radius: 10px;
}


@media only screen and (min-device-width: 720px) {
  .myCasaSubtitle {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 900px){
  .toggle-container{
    padding-top: 10px;
    
  }

}