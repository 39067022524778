$el-background: #fff1c5;

p.el-text {
  margin-top: 16px;
}

.background {
  background-color: $el-background;
}

h2 {
  font-size: 48px;
}

.custom-big-text {
  font-size: 1.8rem;
}

.custom-small-text {
  font-size: 18px;
}

#how-it-works {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 50px;

  margin-bottom: 30px;
}

.sub-mid-title{
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 50px;
  
}

div.sub-mid-title{
  padding-top: 40px;
}

