$el-background: #fff1c5;
$el-black: #333;
$el-white: #fff;

.background {
  background-color: $el-background;
}

.get-it {
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 50px;
  margin-bottom: 40px;
  padding: 0;
  
}

h3 {
  font-size: 36px;
  line-height: 45px;
}

.card {
  min-height: 100%;
}

.card-get-it {
  width: 85%;
  border-radius: 10px;
}

.custom-disabled {
  border: 1px solid #139929;
  opacity: 1 !important;
  background-color: $el-white !important;
  color: $el-black !important;
  cursor: default !important;
  pointer-events: none;
}

.custom-disabled:hover {
  background-color: $el-white;
  color: $el-black;
  cursor: default;
}

.sub-last-container{
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* On screens that are 700px or less, set the background color to blue */
@media screen and (min-width: 700px) {
  .card-get-it {
    width: 100%;
  }
}
