.house-image{
    display: flex;
    padding-top: 175px;
    padding-left: 7px;
    height: 200px;
    width: 300px;
    border-radius: 10%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -18px;
}
.house-img-tag{
    visibility: hidden;
    width: 0;
    height: 0;
}

.photo-grade{
    margin-top: 100px;
   
   
}

@media only screen and (max-width: 900px){
    .house-image{
        visibility: hidden;
        width: 0;
        height: 0;
    }
    .house-img-tag{
        visibility: visible;
        
    }
}