.edit-backdrop{
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
}

.edit-modal {
  position: absolute;
  width: 70vw;
  z-index: 1040;
  top: 20%;
  left: 15%;
  border: 1px solid #e5e5e5;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 10px;
}

#modal-label{
    color: magenta
}

.edit-window{
  margin-top: -7%;
  margin-left: -30%;
  width: 160%;
  
}

.edit-close{
  position: absolute;
  top: 2.7%;
  left: 95%;
  
}