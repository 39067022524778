// @import "~bootstrap/scss/bootstrap";

.myCasaContainer{
  
  width: 80%;
  margin-left: 10%;
  margin-top: 5%;
  margin-bottom: 10%;
  // .myCasaTitle {
  //   margin-left: 3%;
  // }
  .showContainer {
    display: flex;
    flex-direction: column;
    .showRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .items {
        width: 300px;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 30px 30px 30px 0px;
        padding: 12px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
      }
    }
  }
}

.frameContainer {
  border: 1px green solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .showImg {
    width: 270px;
    height: 150px;
    
  }

}


.blankContainer {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #FA910B;
  .blankFrame {
    
    width: 270px;
    height: 150px;
    background-color: #E9E9E9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    .placer {
      
      width: 30%;
    }
    .postedImg {
      width: 100%;
      border-radius: 10px;
    }
  }
  .blankLabel {
    margin-top: 10px;
    color: #FA910B;
    font-weight: bold;
    // width: 80%;
    text-align: left;
    align-self: flex-start;
  }
}


.postedContainer {
  
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  text-decoration: none;
  .postedFrame {
    
    width: 270px;
    height: 150px;
    background-color: #E9E9E9;
    border-radius: 10px;
    display: flex;

    // CSS for my casa dashboard images
    .postedImg {
      width: 270px;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .updateContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    .deleteBtn {
      border: none;
      background-color: #fff;
      color: #FA910B;
    }
  }
  .accordion {
    padding-top: 0;
    .card {
      border-style: hidden;
      .header {
        background-color: white;
        border-style: hidden;
        padding: 0;
        .toggleShow {
          text-decoration: none;
          border: 1px white;
          background-color: white;
          outline:none;
          display: flex;
          justify-content: space-between;
          width: 100%;
          .postedLabel {
            margin-top: 10px;
            color: #FA910B;
            font-weight: bold;
            width: 80%;
            margin-left: -5px;
            text-align: left;
          }
          .detailArr {
            margin-top: 10px;
            color: #FA910B;
            width: 20%;
            margin-right: -18px;
            .down {
              width: 50%;
              margin-bottom: 3px;
            }
          }
        }
      }
      .cardBody {
        height: 250px;
        width: 270px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // .postedInfo {
        //   //missing content
        // }
      }
    }
  }
}

// house create
.createContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
  .createTitle {
    margin: 6% auto 6% 20%;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }
  .createBox {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-left: 30%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 40px;
  }
  .frames {
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 280px;
    border: 1px dashed #979797;
    box-sizing: border-box;
    border-radius: 10px;
    .imgAttached {
      object-fit: cover;
      height: 100%;
      width: 100%;
      cursor: pointer;
      border-radius: 10px;
    }
    .placer {
      // border: 1px solid hotpink;
      width: 30%;
      // object-fit: cover;
      // height: 100%;
      // width: 100%;
      // cursor: pointer;
      // border-radius: 10px;
    }
  }
  .fileUpload {
      display: none;
  }
  .helpContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    .help {
      width: 18px;
      height: 18px;
      margin-left: 6px;
    }
    .tooltipContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      border-radius: 10px;
      background-color: white;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      width: 450px;
      height: 343px;
      padding: 2px;
      .sampleImg {
        border-radius: 10px;
        width: 90%;
        height: 80%;
        margin-left: 5%;
        margin-top: 3%;
      }
      .sampleText {
        color: black;
        width: 90%;
        margin-left: 5%;
        height: 20%;
        padding-top: 2%;
        text-align: center;
      }
    }
  }
  .inputContainer {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
  .inputRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
    width: 100%;
    .inputItem {
      display: flex;
      flex-direction: column;
      width: 45%;
    }
  }
}


.selectInput {
  height: 55px;
  border-radius: 5px;
  border: 1px solid #979797;
  padding-left: 5px;
}
.notValid {
  border: 1px solid #D22E2E;
}

.radioInput-right {
  margin-left: 30%;
}

.inputLabel {
  font-size: 17px;
  font-weight: 400;
  display: flex;
  .tipContainer {
    display: flex;
    align-items: center;
    margin-left: 8px;
    .help {
      width: 16px;
      height: 16px;
    }
    .tip {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background-color: white;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      width: 450px;
      padding: 10px;
      .tipText {
        color: black;
        width: 90%;
        text-align: center;
      }
    }
  }
}

.editContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
  .editTitle {
    margin: 6% auto 6% 20%;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }
  .editBox {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-left: 30%;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 40px;
  }
  .helpContainer {
    display: flex;
    align-items: baseline;
    .help {
      width: 16px;
      height: 16px;
      margin-left: 8px;
    }
    .tooltipContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      border-radius: 10px;
      background-color: white;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      width: 450px;
      height: 343px;
      padding: 2px;
      .sampleImg {
        border-radius: 10px;
        width: 90%;
        height: 80%;
        margin-left: 5%;
        margin-top: 3%;
      }
      .sampleText {
        color: black;
        width: 90%;
        margin-left: 5%;
        height: 20%;
        padding-top: 2%;
        text-align: center;
      }
    }
  }
  .frames {
    display: flex;
    height: 280px;
    justify-content: center;
    align-items: center;
    border: 1px dashed #979797;
    box-sizing: border-box;
    border-radius: 10px;
    .imgAttached {
      object-fit: cover;
      height: 100%;
      width: 100%;
      cursor: pointer;
      border-radius: 10px;
    }
    .placer {
      width: 30%;
    }
  }
  .fileUpload {
    border: 1px solid red;
    display: none;
  }
  .inputContainer {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
  .inputRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .inputItem {
      display: flex;
      flex-direction: column;
      width: 45%;
    }
  }
}
.radioNext {
  margin-left: 20px;
}

.spanNext {
  margin-left: 5px;
}

.gray {
  filter: grayscale(100%);
  color: gray;
}

@media only screen and (max-width: 900px){
  .myCasaContainer {
    .showContainer {
      display: flex;
      flex-direction: column;
      .showRow {
        display: flex;
        flex-direction: column;
      }
    }
  }


}


@media only screen and (max-width: 950px){
  .createContainer {
    width: 100%;
    .createBox {
      width: 90%;
      margin-left: 5%;
    }
  }
    .editContainer {
      width: 100%;
      .editBox {
        width: 90%;
        margin-left: 5%;
      }
    }
}






/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .myCasaContainer {
      .showContainer {
        display: flex;
        flex-direction: column;
        .showRow {
          display: flex;
          flex-direction: column;
        }
      }
    }
}



// /* Portrait */
// @media only screen
//   and (min-device-width: 320px)
//   and (max-device-width: 480px)
//   and (-webkit-min-device-pixel-ratio: 2)
//   and (orientation: portrait) {
// }

// /* Landscape */
// @media only screen
//   and (min-device-width: 320px)
//   and (max-device-width: 480px)
//   and (-webkit-min-device-pixel-ratio: 2)
//   and (orientation: landscape) {

// }

// /* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

// /* Portrait and Landscape */
// @media only screen
//   and (min-device-width: 320px)
//   and (max-device-width: 568px)
//   and (-webkit-min-device-pixel-ratio: 2) {

// }

// /* Portrait */
// @media only screen
//   and (min-device-width: 320px)
//   and (max-device-width: 568px)
//   and (-webkit-min-device-pixel-ratio: 2)
//   and (orientation: portrait) {
// }

// /* Landscape */
// @media only screen
//   and (min-device-width: 320px)
//   and (max-device-width: 568px)
//   and (-webkit-min-device-pixel-ratio: 2)
//   and (orientation: landscape) {

// }

// /* ----------- iPhone 6, 6S, 7 and 8 ----------- */

// /* Portrait and Landscape */
// @media only screen
//   and (min-device-width: 375px)
//   and (max-device-width: 667px)
//   and (-webkit-min-device-pixel-ratio: 2) {

// }

// /* Portrait */
// @media only screen
//   and (min-device-width: 375px)
//   and (max-device-width: 667px)
//   and (-webkit-min-device-pixel-ratio: 2)
//   and (orientation: portrait) {

// }

// /* Landscape */
// @media only screen
//   and (min-device-width: 375px)
//   and (max-device-width: 667px)
//   and (-webkit-min-device-pixel-ratio: 2)
//   and (orientation: landscape) {

// }

// /* ----------- iPhone 6+, 7+ and 8+ ----------- */

// /* Portrait and Landscape */
// @media only screen
//   and (min-device-width: 414px)
//   and (max-device-width: 736px)
//   and (-webkit-min-device-pixel-ratio: 3) {

// }

// /* Portrait */
// @media only screen
//   and (min-device-width: 414px)
//   and (max-device-width: 736px)
//   and (-webkit-min-device-pixel-ratio: 3)
//   and (orientation: portrait) {

// }

// /* Landscape */
// @media only screen
//   and (min-device-width: 414px)
//   and (max-device-width: 736px)
//   and (-webkit-min-device-pixel-ratio: 3)
//   and (orientation: landscape) {

// }

// /* ----------- iPhone X ----------- */

// /* Portrait and Landscape */
// @media only screen
//   and (min-device-width: 375px)
//   and (max-device-width: 812px)
//   and (-webkit-min-device-pixel-ratio: 3) {

// }

// /* Portrait */
// @media only screen
//   and (min-device-width: 375px)
//   and (max-device-width: 812px)
//   and (-webkit-min-device-pixel-ratio: 3)
//   and (orientation: portrait) {

// }

// /* Landscape */
// @media only screen
//   and (min-device-width: 375px)
//   and (max-device-width: 812px)
//   and (-webkit-min-device-pixel-ratio: 3)
//   and (orientation: landscape) {

// }


@media only screen and (max-device-width: 900px) {
  .myCasaContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 97%;
    margin-left: 1.5%;
    // margin-right: 100px;
    padding-left: 2%;
    padding-right: 2%;
    .myCasaTitle {
      margin-left: 5%;
    }

    .showContainer {
      .showRow {
        .items {
          margin: 30px;
        }
      }
    }

  }  
}

