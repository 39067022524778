

.electrify_container {
  width: 70%;
  margin-left: 15%;
  margin-top: 4%;
  .item_container {
    display: flex;
    margin-top: 4%;
    .img_sub {
      display: flex;
      flex-direction: column;
      .electri_box {
        width: 250px;
        height: 198.33px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        // align-items: center;
        
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 200px;
          
        }
      }
      .electri_text {
        text-align: center;
      }
    }
    // .text_sub {
    //   width: 80%;
    //   margin-left: 10%;
    //   .p1,
    //   .p2,
    //   .p3,
    //   .p4 {
    //     margin-bottom: 5%;
    //   }
    // }
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2){

}


