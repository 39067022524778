    .home-link{
      display: inline-flex;
      justify-content: flex-end;
      margin-top: 22px;
    }
      

  .electrify-title{
    margin-bottom: 4rem;
  }


  .h3{
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
  }
  .item_container {
    
    
    .img_sub {
      
      .electri_box {
        width: 255px;
        height: 198px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        // align-items: center;
        margin-bottom: 58px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 110px;
          height: auto;
          
        }
      }
      .electri_text {
        
        text-align: center;
      }
    }
    .text_sub {
      
      .p1,
      .p2,
      .p3,
      .p4 {
        margin-bottom: 5%;
      }
    }
  }
  

  @media only screen and (min-device-width: 320px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 2){
    .img_sub{
      
      margin-left: 2rem;
      margin-bottom: 2rem;
    }
  }