h4{
color: #333333, 100%;
font-family: Mulish;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 23px;
}

.admin-card{
background: #FFFFFF;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
/* width: 900px; */
/* height: 455px; */
margin: 15px auto;


}

.admin-col{
margin-bottom: 20px;
align-self: center;
    
}

.admin-card-header {
/* width: 860px; */
display: inline-flex;
flex-direction: row;
justify-content: space-between;
margin: 23px 0 -20px 0;
    
}

.img {
    width: 390px;
    height: 260px;
    object-fit: cover;
    border-radius: 10px;
}