.flex-end{
    /* width: 900px; */
    display: inline-flex;
    justify-content: flex-end;
    margin-top: 22px;
}

div.verified{
    display: inline-flex;
   

}
p.verified{
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    margin: 8px 20px 0 0;
    color: #333333;
    
    
}

p.not-verified{
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    margin: 8px 20px 0 0;
    color: #979797;
    
}