.loading-img-container{
  display: flex;
  align-content: center;
  justify-content: center;
  
}

.pulse-logo {
    
    
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    margin: 10px;
    /* height: 20px;
    width: 20px; */
    transform: scale(1);
    animation: pulse 2s infinite;
  }


@keyframes pulse {
    0% {
      transform: scale(0.95);
      /* box-shadow: 0 0 0 0 #FDC825; */
    }
    
    70% {
      transform: scale(1.3);
      /* box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); */
    }
    
    100% {
      transform: scale(0.95);
      /* box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); */
    }
  }